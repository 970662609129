import React from "react";
import "./PsychologistProfile.scss";
import ProfileImage from "../../Images/user.png";
import { FaCamera, FaPaperPlane, FaPhone, FaStar } from "react-icons/fa";
import { ButtonCircle } from "../../components/Button/ButtonCircle";

const PsychologistProfile = () => {
    return (
        <div className="" >
            <div className="container mx-auto">
                <div className="psycholog-profile">
                    <div className="grid grid-cols-12 gap-4 max-sm:!block">
                        <div className="col-span-6">
                            <div className="profile-image-parent justify-center">
                                <div className="max-sm:!ml-0 profile-area-full">
                                    <div className="profile-image">
                                        <img src={ProfileImage} alt="Profile" />
                                    </div>
                                    <div className="online-area">
                                        Çevrimiçi
                                        <span className="online-circle"></span>
                                    </div>
                                    <div className="preliminary-interview2">
                                        Ön Görüşme
                                        <span className="online-circle"></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="info col-span-6">
                            <h1 className="max-sm:!flex max-sm:!justify-center">Aykut Altunışık</h1>
                            <span className="subtitle max-sm:!flex max-sm:!justify-center">Öğretim Üyesi Klinik Psikolog</span>
                            <div className="counts max-sm:!flex max-sm:!justify-center">
                                <span>Danışan : 999</span>
                                <span>Seans : 999</span>
                            </div>
                            <div className="meeting-info max-sm:!flex max-sm:!justify-center">
                                <span className="max-sm:text-[13px]">30dk</span>
                                <span className="max-sm:text-[13px]">200 ₺</span>
                            </div>
                            <div className="stars max-sm:!flex max-sm:!justify-center">
                                <FaStar />
                                <FaStar />
                                <FaStar />
                                <FaStar />
                                <FaStar />
                            </div>
                            <div className="buttons max-sm:!w-full">
                                <ButtonCircle
                                    style={{ width: "50px", height: "50px" }}
                                    className={"pyschologistProfileIcon"}
                                    Icon={FaPhone}
                                />
                                <ButtonCircle
                                    style={{ width: "50px", height: "50px" }}
                                    className={"pyschologistProfileIcon"}
                                    Icon={FaCamera}
                                />
                                <ButtonCircle
                                    style={{ width: "50px", height: "50px" }}
                                    className={"pyschologistProfileIcon"}
                                    Icon={FaPaperPlane}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PsychologistProfile;
