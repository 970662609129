import React from "react";
import "./button.css";

export const ButtonPrimary = ({ Text, style, className, onClick }) => {
  return (
    <button
      style={{ ...style }}
      onClick={onClick}
      class={`bg-[#146C94] hover:bg-[#146C94] text-white text-[16px] rounded-full ${className}`}
    >
      {Text}
    </button>
  );
};
