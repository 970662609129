import React from "react";
import Header from "../../components/Headers/HeaderDefault";
import Footer from "../../components/Footer/footer";
import { DateCard } from "../../components/Cards/DateCard";
import Profile from "../../Images/user.png";
import TopComponent from "../PsychologistProfile/TopComponent";
import DateDetail2 from "../../components/Cards/DateDetail2";
import { ButtonPrimary } from "../../components/Button/ButtonPrimary";
import ClientNote from "../../components/Cards/ClientNote";

export const PsychologistPanel = () => {
  return (
    <>
      <Header />

      <section>
        <div className="container mx-auto">
          <div className="buttongroup-third max-sm:!block">
            <ButtonPrimary
              className="max-sm:!m-[5px] max-sm:!w-full tabBtnPrimary active"
              style={{
                width: "310px",
                height: "50px",
                justifycontent: "center",
                textalign: "center",
                margin: "30px",
              }}
              Text={"Yeni Randevular"}
            />
            <ButtonPrimary
              className="max-sm:!m-[5px] max-sm:!w-full"
              style={{
                width: "310px",
                height: "50px",
                justifycontent: "center",
                textalign: "center",
                margin: "30px",
              }}
              Text={"Geçmiş Randevular"}
            />
            <ButtonPrimary
              className="max-sm:!m-[5px] max-sm:!w-full"
              style={{
                width: "310px",
                height: "50px",
                justifycontent: "center",
                textalign: "center",
                margin: "30px",
              }}
              Text={"İptal Edilenler"}
            />
            <ButtonPrimary
              className="max-sm:!m-[5px] max-sm:!w-full"
              style={{
                width: "310px",
                height: "50px",
                justifycontent: "center",
                textalign: "center",
                margin: "30px",
              }}
              Text={"Onaylanan Randevular"}
            />
          </div>
          <div className="infos-cards w-full">
            <DateDetail2 />
            <div style={{ height: "20px" }} />
            <DateDetail2 />
            <div style={{ height: "20px" }} />
            <DateDetail2 />
          </div>
        </div>
      </section>

      <section style={{ marginBottom: "150px" }}>
        <div className="container mx-auto">
          <div className="comments-cards">
            <p className="headers montserrat-b text-center mb-5">
              Danışan Notları
            </p>
            <div className="clientnodes max-sm:!block" style={{ display: "flex", justifyContent: "center" }}>
              <ClientNote />
              <ClientNote />
            </div>
          </div>
        </div>
        <div>
          <TopComponent />
          <div className="infos-section max-sm:!hidden"></div>
          <div className="card-section-PH max-sm:!mt-[20px]">
            <div className="reserve-input">
              <input type="text" placeholder="İsim: İsim Soyisim" className="max-sm:!m-[10px] !w-[600px] !pr-0 max-sm:!w-full max-sm:!text-[15px]" />
              <input
                type="text"
                className="max-sm:!m-[10px] max-sm:!w-full max-sm:!text-[15px] !pr-0 !w-[600px] input-for-font"
                placeholder="E-Posta: isimsoyisim@eposta.com"
              />
              <input type="tel" placeholder="Telefon: +90|XXX XXX XX XX" className="max-sm:!m-[10px] !w-[600px] !pr-0 max-sm:!w-full max-sm:!text-[15px]" />
              <input
                type="text"
                className="max-sm:!m-[10px] max-sm:!w-full max-sm:!text-[15px] !pr-0 !w-[600px] input-for-font"
                placeholder="Hakkımda:"
                style={{ height: "300px" }}
              />
              <input
                type="text"
                className="max-sm:!m-[10px] max-sm:!w-full max-sm:!text-[15px] !pr-0 !w-[600px] input-for-font"
                style={{ height: "300px" }}
                placeholder="Eğitim Bilgisi:"
              />
            </div>
            <div className="reserve-button">
              <button className="montserrat-m">Düzenle & Kaydet</button>
            </div>

          </div>
        </div>
      </section>

      <Footer />
    </>
  );
};
