import React from "react";
import "./index.css";
import App from "./App";
import { Route, Routes } from "react-router-dom";
import { Experts } from "./pages/Experts/index";
import { PsychologistProfile } from "./pages/PsychologistProfile";
import { MakingAppointment } from "./pages/MakingAppointment/MakingAppointment";
import { MakingAppointmentTwo } from "./pages/MakingAppointment/MakingAppointmentTwo";
import { PsychologistPanel } from "./pages/PsychologistPanel/PsychologistPanel";
import { Login } from "./pages/Login";
import { Apply } from "./pages/Apply";
import { PaymentHistoryNd } from "./pages/UserPanel/PaymentHistoryNd";
import { PaymentHistorySt } from "./pages/UserPanel/PaymentHistorySt";
import { ExpertRegistration } from "./pages/ExpertRegistration/ExpertRegistration";
import { UserRegistration } from "./pages/ExpertRegistration/UserRegistration";
import { ExpertsLogin2 } from "./pages/Login/ExpertsLogin2";
import { Contact } from "./pages/Contact";
import { PsychoTest } from "./pages/PsychoTest";
import { ComingSoon } from "./pages/ComingSoon";
import { TumPsikologlar } from "./pages/Experts/TumPsikologlar";
import { TumAvukatlar } from "./pages/Experts/TumAvukatlar";
import { TumDiyetisyenler } from "./pages/Experts/TumDiyetisyenler";
import { TumOgretmenler } from "./pages/Experts/TumOgretmenler";

const RouterComponents = () => {
  return (
    <>
      <Routes>


        <Route path="/" element={<App />} />
        <Route path="/experts" element={<Experts />} />
        <Route path="/psikolog" element={<PsychologistProfile />} />
        <Route path="/make-appointment" element={<MakingAppointment />} />
        <Route
          path="/make-appointment-two"
          element={<MakingAppointmentTwo />}
        />
        <Route path="/login" element={<Login />} />
        <Route path="/user-panel" element={<PaymentHistoryNd />} />
        <Route path="/payment-history" element={<PaymentHistorySt />} />
        <Route path="/psikolog-panel" element={<PsychologistPanel />} />
        <Route path="/expert-registration" element={<ExpertRegistration />} />
        <Route path="/user-registration" element={<UserRegistration />} />
        <Route path="/expert-login" element={<ExpertsLogin2 />} />
        <Route path="/apply" element={<Apply />} />
        <Route path="/test" element={<PsychoTest />} />
        <Route path="/coming-soon" element={<ComingSoon />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/tum-psikologlar" element={<TumPsikologlar />} />
        <Route path="/tum-avukatlar" element={<TumAvukatlar />} />
        <Route path="/tum-diyetisyenler" element={<TumDiyetisyenler />} />
        <Route path="/tum-ogretmenler" element={<TumOgretmenler />} />
      </Routes>
    </>
  );
};

export default RouterComponents;
