import React from 'react';
import './App.css';
import { HomePage } from './pages/HomePage/HomePage';


function App() {
    return ( <
        >

        <
        HomePage / >
        <
        />
    );
}

export default App;