import React, { useState } from "react";
import "./PsychoCard.css";
import ReactCountryFlag from "react-country-flag";
import { ButtonPrimary } from "../Button/ButtonPrimary";
import "../../App.css";
import { ButtonCircle } from "../Button/ButtonCircle";
import Profile from "../../Images/user.png";
import {
  BsFillSendCheckFill,
  BsTelephone,
  BsCameraVideo,
  BsCalendarDate,
} from "react-icons/bs";
import RandevuModal from "../Modal/RandevuModal";
import { Link } from "react-router-dom";

export const PsychoCard = ({ style, title }) => {
  const [readMore, setReadMore] = useState(false);
  const info =
    "Merhabalar, Ben Psikolog Melek Mercan. Kayseri Nuh Naci Yazgan Üniversitesi Psikoloji bölümü mezunuyum..";

  return (
    <>
      <div className="card-all" style={{ ...style }}>
        <Link to={"/psikolog"}>
          <div className="card-profile">
            <div className="pyschoCardProfileImage max-lg:!w-[70px] max-lg:!h-[70px]">
              <img src={Profile} alt="" />
            </div>
            <div className="preliminary-interview ">Ön Görüşme</div>
            <div className="online-info">
              <p>Çevrimiçi</p>
              <span className="online"></span>
            </div>
          </div>
        </Link>
        <div className="card-body">
          <div className="psycho-info mt-2 max-lg:mt-0">
            <h1 className="montserrat-eb max-lg:!text-[18px]">
              Aykut Altunışık
            </h1>
            <h4 className="montserrat-r max-lg:!text-[14px]"> {title}</h4>
            <div className="client-seans montserrat-m ">
              <p className="pr-2 max-lg:!text-[12px]">Danışan: 999</p>
              <p className="max-lg:!text-[12px]"> Seans: 999</p>
            </div>

            <div className="price-flag">
              <div className="flag">
                <ReactCountryFlag className="tr" countryCode="TR" svg />
                <ReactCountryFlag className="de" countryCode="DE" svg />
              </div>
              <div className="price">
                <p className="price1">30 dk</p>
                <p className="price2">200 ₺</p>
              </div>
            </div>
            <div className="terapist max-lg:!hidden">
              <Link to="/psikolog">
                <p className="montserrat-s">HAKKINDA</p>
              </Link>
            </div>
          </div>
          <div className="psycho-footer">
            <div className="buttons-homepage max-lg:!mt-0">
              <Link to="/make-appointment" className="max-lg:!hidden">
                <ButtonPrimary
                  style={{
                    width: "192px",
                    height: "48px",
                    fontWeight: "700",
                  }}
                  Text={"Randevu Al"}
                />
              </Link>
              <div className="circles max-lg:!mt-0">
                <ButtonCircle
                  className="psychoCardIcon"
                  style={{ width: "34px", height: "34px", marginRight: "10px" }}
                  Icon={BsTelephone}
                />
                <ButtonCircle
                  className="psychoCardIcon"
                  style={{ width: "34px", height: "34px", marginRight: "10px" }}
                  Icon={BsCameraVideo}
                />
                <ButtonCircle
                  className="psychoCardIcon"
                  style={{ width: "34px", height: "34px", marginRight: "10px" }}
                  Icon={BsFillSendCheckFill}
                />
                <ButtonCircle
                  className="psychoCardIcon"
                  style={{ width: "34px", height: "34px" }}
                  Icon={BsCalendarDate}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
