import React from "react";
import "./DateCard.css";
import Profile from "../../Images/user.png";
import { ButtonCircle } from "../Button/ButtonCircle";
import {
  BsCameraVideo,
} from "react-icons/bs";
export const DateCard = (style) => {
  return (
    <>
      <div className="DateCard userPanelDateCard" style={{ ...style }}>
        <div className="top-section">
          <div className="left-section">
            <div className="card-profil-img dateCardImage">
              <img src={Profile} alt="" />
            </div>
            <p>Kullanıcı</p>
            <p className="montserrat-b ">İsim SOYİSİM</p>
          </div>
          <div className="right-section">
            {/* <div className="button-text">Terapist: İsim Soyisim</div> */}
            <ul className="montserrat-m">
              <li>Tarih: 08.07.2023</li>
              <li>Saat:14:35</li>
              <li>Seans:2</li>
              <li>Ücret:250TL</li>
              <li>
                <ButtonCircle
                  className="dateCardIcon"
                  style={{ width: "30px", height: "30px" }}
                  Icon={BsCameraVideo}
                />
              </li>
            </ul>
          </div>
        </div>
        <div className="down-section">
          <p className="montserrat-m">08.07.2023</p>
          <p>Onay Bekliyor</p>
        </div>
      </div>
    </>
  );
};
