import React from 'react'
import "./DateCard.css";
import Profile from "../../Images/user.png";
import { ButtonCircle } from "../Button/ButtonCircle";
import {
  BsCameraVideo,
} from "react-icons/bs";
import './DateDetail.css';
import { FaCheck, FaTimes } from 'react-icons/fa';

export default function DateDetail2({style = {}}) {
  return (
    
    <div className='grid max-sm:!block grid-cols-12 w-full max-sm:!mx-0 max-sm:!my-[20px]'>
        <div className='col-span-2'></div>
        <div className='col-span-8'>
            <div className="DateCard2 max-sm:!block  userPanelDateCard2 align-center !p-[20px] !h-auto !w-full" style={{ ...style }}>
                <div className='grid grid-cols-12 max-sm:!block'>
                    <div className='col-span-6'>
                        <div className="top-section max-sm:!block items-center">
                            <div className="left-section">
                                <div className="card-profil-img dateCardImage !mb-0">
                                <img src={Profile} alt="" />
                                </div>
                            </div>
                            <div className="right-section !m-0" style={{border:"none"}}>
                                {/* <div className="button-text">Terapist: İsim Soyisim</div> */}
                                <ul className="montserrat-m max-sm:!mt-[20px] max-sm:!w-full  max-sm:!ml-[30px]">
                                <li>İsim Soyisim</li>
                                <li>+90 555 555 5555</li>
                                <li>isimsoyisim@gmail.com </li>
                                <li>
                                    <ButtonCircle
                                    className="dateCardIcon"
                                    style={{ width: "30px", height: "30px" }}
                                    Icon={BsCameraVideo}
                                    />
                                </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className='col-span-6 max-sm:!mt-[20px] border-black border-collapse border-solid border-l-2 max-sm:!border-none' >
                        <div className="top-section max-sm:!block !h-full !items-center">
                            <div className="left-section ">
                                {/* <div className="button-text">Terapist: İsim Soyisim</div> */}
                                <ul className="montserrat-m  max-sm:!w-full  max-sm:!ml-[30px]">
                                    <li>Tarih: 08.07.2023</li>
                                    <li>Saat:14:35</li>
                                    <li>Seans:2</li>
                                    <li>Ücret:250TL</li>
                                </ul>
                            </div>
                            <div className="right-section max-sm:!flex-row max-sm:!mt-[15px] !flex !flex-col !justify-evenly !items-end !mr-[20px] !h-full !m-0" style={{border:"none"}}>
                                <ButtonCircle Icon={FaCheck} style={{backgroundColor:"#14FF00",padding:"10px"}}/>
                                <ButtonCircle Icon={FaTimes} style={{backgroundColor:"#FF2121",padding:"10px"}}/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
      </div>
  )
}
