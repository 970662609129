import React, { useState } from 'react';
import './pagination.css';

const Pagination = ({ itemsPerPage, totalItems, onPageChange }) => {
    const [currentPage, setCurrentPage] = useState(1);

    const totalPages = Math.ceil(totalItems / itemsPerPage);

    const handlePageChange = (pageNumber) => {
        if (pageNumber < 1 || pageNumber > totalPages) {
            return;
        }

        setCurrentPage(pageNumber);
        onPageChange(pageNumber);
    };

    const renderPageNumbers = () => {
        const pageNumbers = [];

        for (let i = 1; i <= totalPages; i++) {
            pageNumbers.push(
                <li
                    key={i}
                    className={i === currentPage ? 'active' : ''}
                    onClick={() => handlePageChange(i)}
                >
                    {i}
                </li>
            );
        }

        return pageNumbers;
    };

    return (
        <div className="pagination">
            <ul className="pagination-list">{renderPageNumbers()}</ul>
        </div>
    );
};

export default Pagination;