import React from "react";
import Header from "../../components/Headers/HeaderDefault";
import Footer from "../../components/Footer/footer";
import { DateCard } from "../../components/Cards/DateCard";
import "./PaymentHistory.css";

export const PaymentHistorySt = () => {
  return (
    <>
      <Header />
      <div className="container mx-auto main">
        <div className="h">
          <p
            className="montserrat-b"
            style={{ fontSize: "30px", margin: "20px" }}
          >
            Randevu Geçmişi
          </p>
        </div>
        <div className="content">
          <DateCard />
          <DateCard />
        </div>
      </div>
      <Footer />
    </>
  );
};
