import React, { useState } from "react";
import HeaderDefault from "../../components/Headers/HeaderDefault";
import "./login.scss";
import { FaFacebook, FaGoogle } from "react-icons/fa";
import { ButtonPrimary } from "../../components/Button/ButtonPrimary";
import Anopia from "../../img/Group-61.jpg";
import { Link, NavLink } from "react-router-dom";
import Footer from "../../components/Footer/footer";

export const ExpertsLogin2 = () => {
  return (
    <>
      <HeaderDefault />
      <div className="container mx-auto" style={{ marginBottom: "100px" }}>
        <div className="login-form w-100  ">
          <div className="grid max-sm:!flex max-sm:flex-col-reverse grid-cols-12">
            <div className="col-span-6 continue-with max-sm:mt-[30px]">
              <h1 className="max-sm:!mb-0">Giriş Yap</h1>

              <div className="user-area max-sm:!mt-[20px]">

                <div className="user max-sm:!mt-[20px]">
                  <label>Kullanıcı Adı:</label>
                  <input type="text" />
                </div>
                <div className="user max-sm:!mt-[20px]">
                  <label>Şifre:</label>
                  <input type="password" />
                </div>
              </div>
              <div className="user">
                <ButtonPrimary
                  style={{ width: "250px", height: "50px", marginTop: "50px" }}
                  Text={"Giriş Yap"}
                />
              </div>
            </div>
            <div
              className="col-span-6  "
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <div className="right">
                <Link to="/expert-login">
                  <button className="active">Uzman</button>
                </Link>
                <Link to="/login">
                  <button className="nt-active">Kullanıcı</button>
                </Link>
              </div>
              <div className="anopia-img">
                <img src={Anopia} alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
};
