import React, { useState } from "react";
import "./Faq.css";
import { BsChevronDown, BsChevronUp, BsDot } from "react-icons/bs";

const FaqItem = () => {
  const [faqData] = useState([
    {
      question: "Lorem ipsum dolor sit amet consectetur adipisicing elit",
      answer: "Cevap 1",
    },
    {
      question: "Lorem ipsum dolor sit amet consectetur adipisicing elit",
      answer: "Cevap 2",
    },
    {
      question: "Lorem ipsum dolor sit amet consectetur adipisicing elit",
      answer: "Cevap 3",
    },
  ]);

  const [expandedIndex, setExpandedIndex] = useState(null);

  const toggleFAQ = (index) => {
    if (expandedIndex === index) {
      setExpandedIndex(null);
    } else {
      setExpandedIndex(index);
    }
  };

  return (
    <div className=" lg:col-span-12 md:col-span-6 sm:col-span-1">
      {faqData.map((faq, index) => (
        <div key={index} className="faq-item mt-5">
          <div
            onClick={() => toggleFAQ(index)}
            className={`faq-question ${expandedIndex === index ? "active" : ""}`}
          >
            <div
              className="flex container"
              style={{
                alignItems: "center",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <div className="flex" style={{ alignItems: "center" }}>
                <BsDot style={{ fontSize: "30px" }} />
                <h3 className="faqQuestion"> {faq.question}</h3>
              </div>
              {expandedIndex !== index ? (
                <BsChevronDown style={{ fontSize: "20px" }} />
              ) : (
                <BsChevronUp style={{ fontSize: "20px" }} />
              )}
            </div>
          </div>
          {expandedIndex === index && <p className="faq-answer">{faq.answer}</p>}
        </div>
      ))}
    </div>
  );
};

export default FaqItem;