import React, { useState } from "react";
import HeaderDefault from "../../components/Headers/HeaderDefault";
import Footer from "../../components/Footer/footer";



export const PsychoTest = () => {
  return (
    <>
      <div className="">

        <HeaderDefault />
        <section>

          <div style={{ marginTop: "200px", marginBottom: "200px", display: "flex", justifyContent: "center" }} className="container mx-auto">
            <h1 style={{ textDecoration: "none", color: "black" }} className="montserrat-b">COMING SOON</h1>
          </div>
        </section >

        <Footer />
      </div >
    </>
  );
};

