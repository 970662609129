import React, { useState } from "react";
import Header from "../../components/Headers/HeaderDefault";
import "./index.css";
import { PsychoCard } from "../../components/Cards/PsychoCard";
import Footer from "../../components/Footer/footer";
import { ButtonPrimary } from "../../components/Button/ButtonPrimary";
import SearchBar from "../../components/SearchBar/Search";
import { BsSortDownAlt, BsFilterLeft, BsArrowRightShort, BsArrowRightCircleFill } from "react-icons/bs";
import Slider from "react-slick";
import { Link } from "react-router-dom";

import { FiChevronDown, FiChevronUp } from 'react-icons/fi';

export const Experts = () => {
  const [isFilterVisible, setIsFilterVisible] = useState(false);
  const [consultingArea, setConsultingArea] = useState("");
  const [gender, setGender] = useState("");
  const [price, setPrice] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [isOpend, setIsOpend] = useState(false);

  const toggleChildBoxnd = () => {
    setIsOpend((prevIsOpen) => !prevIsOpen);
  };


  const toggleChildBox = () => {
    setIsOpen((prevIsOpen) => !prevIsOpen);
  };

  const showFilter = () => {
    setIsFilterVisible(true);
  };

  const hideFilter = () => {
    setIsFilterVisible(false);
  };

  const handleBackgroundClick = (event) => {
    if (event.target.closest(".filter-content")) {
      return;
    }
    hideFilter();
  };

  const handleConsultingAreaChange = (e) => {
    setConsultingArea(e.target.value);
  };

  const handleGenderChange = (e) => {
    setGender(e.target.value);
  };

  const handlePriceChange = (e) => {
    setPrice(e.target.value);
  };

  var settings = {
    arrows: true,
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    initialSlide: 0,

    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <>
      <div className="">
        <Header />

        {isFilterVisible && (
          <div id="modal" className="modal" onClick={handleBackgroundClick}>
            <div className="modal-content "  >
              <div className="filter-overlay" id="filterOverlay" style={{ display: "flex", flexDirection: "column" }}>
                <div className="filter-content scrollable-container">


                  <h2 style={{ display: "flex" }} className="montserrat-m"> <BsFilterLeft style={{ fontSize: "30px" }} /> Filtrele
                    {/* Kapatma Düğmesi */}
                    <button
                      className="close-filter-btn"
                      id="closeFilterBtn"
                      onClick={hideFilter}
                    >
                      &times;
                    </button>

                  </h2>


                  {/* Danışmanlık Alanı */}
                  <div className="" style={{ backgroundColor: "white", borderRadius: "15px", padding: "0px 10px", margin: "10px 0px", border: "1px solid gray" }}>
                    <div className="parent-box " onClick={toggleChildBox}>
                      Danışman Seçimi {isOpen ? <FiChevronUp /> : <FiChevronDown />}
                    </div>

                    {isOpen && (
                      <div className="child-box">
                        <label>
                          <input type="checkbox" name="option1" value="Option 1" /> Seçenek 1
                        </label>
                        <br />
                        <label>
                          <input type="checkbox" name="option2" value="Option 2" /> Seçenek 2
                        </label>
                        <br />
                        <label>
                          <input type="checkbox" name="option3" value="Option 3" /> Seçenek 3
                        </label>
                        <br />
                      </div>
                    )}
                  </div>



                  {/* Terapi Türü */}
                  <div className="" style={{ backgroundColor: "white", borderRadius: "15px", padding: "0px 10px", margin: "10px 0px", border: "1px solid gray" }}>
                    <div className="parent-box" onClick={toggleChildBoxnd}>
                      Terapi Türü {isOpend ? <FiChevronUp /> : <FiChevronDown />}
                    </div>

                    {isOpend && (
                      <div className="child-box">
                        <label>
                          <input type="checkbox" name="option1" value="Option 1" /> Seçenek 1
                        </label>
                        <br />
                        <label>
                          <input type="checkbox" name="option2" value="Option 2" /> Seçenek 2
                        </label>
                        <br />
                        <label>
                          <input type="checkbox" name="option3" value="Option 3" /> Seçenek 3
                        </label>
                        <br />
                      </div>
                    )}
                  </div>
                  {/* Cinsiyet Alanı */}
                  <form>

                    <label for="gender">Cinsiyet:</label>
                    <select name="gender">
                      <option value="">Seçiniz…</option>
                      <option value="female">Kadın</option>
                      <option value="male">Erkek</option>
                      <option value="other">Hepsi</option>
                    </select>
                  </form>




                  <h2 style={{ display: "flex", margin: "10px 0px" }}>
                    <BsSortDownAlt style={{ fontSize: "30px" }} />
                    Sıralama:
                    {/* Kapatma Düğmesi */}
                  </h2>


                  {/* Fiyat */}
                  <div>
                    <label htmlFor="price">Fiyat:</label>
                    <select>
                      <option value="someOption">Varsayılan</option>
                      <option value="otherOption">Artan</option>
                      <option value="otherOption">Azalan</option>
                    </select>
                  </div>
                  {/* Danışman Özellikleri */}
                  <div>
                    <label htmlFor="price">Aktiflik Durumu:</label>
                    <select>
                      <option value="someOption">Varsayılan</option>
                      <option value="otherOption">Çevrimiçi</option>
                      <option value="otherOption">Çevrimdışı</option>

                      <option value="otherOption">Hepsi</option>
                    </select>
                  </div>
                  <ButtonPrimary
                    style={{
                      width: "192px",
                      height: "48px",
                      fontWeight: "700",
                    }}
                    onClick={hideFilter}
                    Text={"Filtrele"}
                  />

                </div>
              </div>
            </div>
          </div>
        )}

        <section>
          <div className="container mx-auto">
            <div className="special-resp-psiko flex items-center justify-between">
              <div className="header-psikolog-expert">
                <Link to="/tum-psikologlar" >
                  <h1 className="montserrat-b cp">PSİKOLOG <BsArrowRightCircleFill style={{ marginLeft: "10px" }}></BsArrowRightCircleFill></h1>
                </Link>
              </div>
              <div className="others-header-psikolog-expert">

                <SearchBar />

                <ButtonPrimary
                  style={{
                    width: "192px",
                    height: "48px",
                    fontWeight: "700",
                  }}
                  onClick={showFilter}
                  Text={"Filtrele"}
                />
              </div>
            </div>

            <div className="pshycoCards w-100" style={{ width: "100%" }}>
              <Slider {...settings}>
                <div>
                  <PsychoCard title={"PSİKOLOG"} />
                </div>
                <div>
                  <PsychoCard title={"PSİKOLOG"} />
                </div>
                <div>
                  <PsychoCard title={"PSİKOLOG"} />
                </div>
                <div>
                  <PsychoCard title={"PSİKOLOG"} />
                </div>
              </Slider>
            </div>
          </div>
        </section>

        <section>
          <div className="container mx-auto">
            <div className="header-psikolog-expert">
              <Link to="/tum-diyetisyenler" >
                <h1 className="montserrat-b cp">DİYETİSYEN <BsArrowRightCircleFill style={{ marginLeft: "10px" }}></BsArrowRightCircleFill></h1>
              </Link>

            </div>
            <div className="pshycoCards w-100" style={{ width: "100%" }}>
              <Slider {...settings}>
                <div>
                  <PsychoCard title={"DİYETİSYEN"} />
                </div>
                <div>
                  <PsychoCard title={"DİYETİSYEN"} />
                </div>
                <div>
                  <PsychoCard title={"DİYETİSYEN"} />
                </div>
                <div>
                  <PsychoCard title={"DİYETİSYEN"} />
                </div>
              </Slider>
            </div>
          </div>
        </section>

        <section style={{ marginBottom: "150px" }}>
          <div className="container mx-auto">
            <div className="header-psikolog-expert">
              <Link to="/tum-avukatlar" >
                <h1 className="montserrat-b cp">AVUKAT <BsArrowRightCircleFill style={{ marginLeft: "10px" }}></BsArrowRightCircleFill></h1>
              </Link>

            </div>
            <div className="pshycoCards w-100">
              <Slider {...settings}>
                <div>
                  <PsychoCard title={"AVUKAT"} />
                </div>
                <div>
                  <PsychoCard title={"AVUKAT"} />
                </div>
                <div>
                  <PsychoCard title={"AVUKAT"} />
                </div>
                <div>
                  <PsychoCard title={"AVUKAT"} />
                </div>
              </Slider>
            </div>
          </div>
        </section>


        <section style={{ marginBottom: "150px" }}>
          <div className="container mx-auto">
            <div className="header-psikolog-expert">
              <Link to="/tum-ogretmenler" >
                <h1 className="montserrat-b cp">ÖĞRETMEN<BsArrowRightCircleFill style={{ marginLeft: "10px" }}></BsArrowRightCircleFill></h1>
              </Link>

            </div>
            <div className="pshycoCards w-100">
              <Slider {...settings}>
                <div>
                  <PsychoCard title={"ÖĞRETMEN"} />
                </div>
                <div>
                  <PsychoCard title={"ÖĞRETMEN"} />
                </div>
                <div>
                  <PsychoCard title={"ÖĞRETMEN"} />
                </div>
                <div>
                  <PsychoCard title={"ÖĞRETMEN"} />
                </div>
              </Slider>
            </div>
          </div>
        </section>

        <Footer />
      </div >
    </>
  );
};