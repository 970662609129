import React, { useState } from "react";
import "./header.css";
import { Link, NavLink } from "react-router-dom";
import logo from "../../img/logo.png";
import { HiMenu } from "react-icons/hi";
import { BsXCircle } from "react-icons/bs";

export default function HeaderDefault() {
  let [isMenuOpen, setMenuOpen] = useState(false);

  const handleBackgroundClick = (event) => {
    if (event.target.closest(".mobile-menu-content")) {
      return;
    }
    closeMobileMenu();
  };

  const closeMobileMenu = () => {
    setMenuOpen(false);
  };

  return (
    <>
      <header>
        <div className="header-container w-full">
          <div className="container mx-auto w-full">
            <div className="header-content flex items-center justify-between w-full pr-3 pl-3">
              <div className="logo-section flex items-center max-lg:justify-between max-lg:w-full p-5">
                <Link to="/">
                  <img src={logo} alt="Logo" className="logo-image w-[150px]" />
                </Link>
                <div onClick={() => setMenuOpen(!isMenuOpen)}>
                  <HiMenu className="mobile-menu-icon lg:hidden text-[25px] cursor-pointer" />
                </div>
              </div>
              <div className="menu-section hidden lg:flex">
                <ul className="menu-list flex space-x-6">
                  <li>
                    <NavLink
                      exact
                      to="/"
                      activeClassName="active"
                      className="menu-link font-bold"
                    >
                      Anasayfa
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to="/experts"
                      activeClassName="active"
                      className="menu-link font-bold"
                    >
                      Uzmanlar
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to="/test"
                      activeClassName="active"
                      className="menu-link font-bold"
                    >
                      Psikolojik Testler
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to="/coming-soon"
                      activeClassName="active"
                      className="menu-link font-bold"
                    >
                      Metaverse
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to="/contact"
                      activeClassName="active"
                      className="menu-link font-bold"
                    >
                      Bize Ulaş
                    </NavLink>
                  </li>
                </ul>
              </div>
              <div className="login-section hidden lg:block">
                <ul className="login-list flex items-center">
                  <li>
                    <Link
                      to="/login"
                      className="login-link"
                      style={{ color: "#146C94", fontWeight: "bold" }}
                    >
                      Giriş
                    </Link>
                  </li>
                  <li
                    className="login-divider mr-1 ml-1"
                    style={{ color: "#146C94", fontWeight: "bold" }}
                  >
                    /
                  </li>
                  <li>
                    <Link
                      to="/user-registration"
                      className="login-link"
                      style={{ color: "#146C94", fontWeight: "bold" }}
                    >
                      Kayıt
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </header>

      {isMenuOpen && (
        <div className="mobile-menu-content  bg-white shadow-md w-[250px] h-full p-5 fixed z-20 top-0">
          <div className="mobile-menu-logo flex justify-between items-center mb-5">
            <Link to="/">
              <img src={logo} alt="Logo" className="logo-image w-[150px]" />
            </Link>
            <BsXCircle
              className="close-mobile-menu-icon cursor-pointer"
              onClick={() => setMenuOpen(!isMenuOpen)}
              style={{ right: "14px" }}
            />
          </div>
          <ul className="mobile-menu-list">
            <li className="mb-3">
              <NavLink
                exact
                to="/"
                activeClassName="active"
                className="mobile-menu-link font-bold "
              >
                Anasayfa
              </NavLink>
            </li>
            <li className="mb-3">
              <NavLink
                to="/experts"
                activeClassName="active"
                className="mobile-menu-link font-bold "
              >
                Uzmanlar
              </NavLink>
            </li>
            <li className="mb-3">
              <NavLink
                to="/test"
                activeClassName="active"
                className="mobile-menu-link font-bold "
              >
                Psikolojik Testler
              </NavLink>
            </li>
            <li className="mb-3">
              <NavLink
                to="/coming-soon"
                activeClassName="active"
                className="mobile-menu-link font-bold "
              >
                Metaverse
              </NavLink>
            </li>
            <li className="mb-3">
              <NavLink
                to="/contact"
                activeClassName="active"
                className="mobile-menu-link font-bold "
              >
                Bize Ulaş
              </NavLink>
            </li>
            <li className="mb-3">
              <NavLink
                to="/login"
                activeClassName="active"
                className="mobile-menu-link font-bold "
              >
                Giriş Yap
              </NavLink>
            </li>
            <li className="mb-3">
              <NavLink
                to="/user-registration"
                activeClassName="active"
                className="mobile-menu-link font-bold "
              >
                Kayıt Ol
              </NavLink>
            </li>
          </ul>
          <div
            className="mobile-menu-overlay fixed"
            onClick={handleBackgroundClick}
          ></div>
        </div>
      )}
    </>
  );
}
