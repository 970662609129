import React, { useState } from "react";
import HeaderDefault from "../../components/Headers/HeaderDefault";
import "./PsychologistProfile.scss";
import ProfileImage from "../../Images/user.png";
import { FaCamera, FaPaperPlane, FaPhone, FaStar, FaAngleDown, FaAngleUp } from "react-icons/fa";
import { ButtonCircle } from "../../components/Button/ButtonCircle";
import ButtonExpert from "../../components/Button/ButtonExpert";
import CommentCard from "../../components/Cards/CommentCard";
import Star from "../../components/Star/Star";
import Footer from "../../components/Footer/footer";
import { ButtonPrimary } from "../../components/Button/ButtonPrimary";
import RandevuModal from "../../components/Modal/RandevuModal";
import Slider from "react-slick";


export const PsychologistProfile = () => {


  const showRandevu = () => {
    setIsRandevuVisible(true);
  };

  const hideRandevu = () => {
    setIsRandevuVisible(false);
  };


  const [isRandevuVisible, setIsRandevuVisible] = useState(false);
  const [toggle, setToggle] = useState(false);
  const [educationToggle, seteducationToggle] = useState(false);
  const [showFull,setShowFull] = useState(false);

  const [activeComment, setActiveComment] = useState(0);
  const [comments, setComments] = useState([
    {
      text: "Lorem ipsum",
      startCount: 4,
    },
    {
      text: "Lorem ipsum",
      startCount: 2,
    },
    {
      text: "Lorem ipsum",
      startCount: 2,
    },
  ]);

  var settings = {
    arrows: true,
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    initialSlide: 0,

    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  return (
    <div className="">


      <HeaderDefault />

      {isRandevuVisible && (
        <div id="modal" className="modal" >
          <div className="modal-content">
            <div className="randevu-overlay" id="randevuOverlay">
              <div className="randevu-content">
                {/* Kapatma Düğmesi */}
                <button
                  className="close-filter-btn"
                  id="closeFilterBtn"
                  onClick={hideRandevu}
                  style={{ top: "140px" }}
                >
                  &times;
                </button>
                <RandevuModal />

              </div>
            </div>
          </div>
        </div>
      )}

      <section>
        <div className="container mx-auto">
          <div className="psycholog-profile">
            <div class="grid max-sm:!block grid-cols-12 ">
              <div className="special-resp col-span-4 ">

                <div className="profile-image-parent ">
                  <div className="profile-area-full max-sm:!ml-0">
                    <div className="profile-image">
                      <img src={ProfileImage} />
                    </div>
                    
                    <div className="online-area">
                      Çevrimiçi
                      <span className="online-circle"></span>
                    </div>
                    <div className="preliminary-interview2">
                      Ön Görüşme
                      <span className="online-circle"></span>
                    </div>
                  </div>
                </div>
                <div className="info col-span-8 hidden max-sm:!block">
                <div className="profil-info" >
                  <h1>Aykut Altunışık</h1>
                  <span className="subtitle">Öğretim Üyesi Klinik Psikolog</span>
                  <div className="counts">
                    <span>Danışan : 999</span>
                    <span>Seans : 999</span>
                  </div>
                  <div className="meeting-info max-sm:justify-center max-sm:!flex">
                    <span>30dk</span>
                    <span>200 ₺</span>
                  </div>
                  <div className="stars">
                    <FaStar />
                    <FaStar />
                    <FaStar />
                    <FaStar />
                    <FaStar />
                  </div>
                  <div className="buttons max-sm:!block max-sm:!w-full">
                    <div className="" >
                      <ButtonPrimary className="max-sm:w-full" Text={"Randevu Al"} style={{ padding: '10px 50px', fontWeight: "700" }} onClick={showRandevu} />
                    </div>
                    <div className="flex max-sm:mt-[20px] max-sm:justify-center" >
                      <ButtonCircle
                        style={{ width: "50px", height: "50px" }}
                        className={"pyschologistProfileIcon max-sm:!mx-[18px]"}
                        Icon={FaPhone}
                      />
                      <ButtonCircle
                        style={{ width: "50px", height: "50px" }}
                        className={"pyschologistProfileIcon  max-sm:!mx-[18px]"}
                        Icon={FaCamera}
                      />
                      <ButtonCircle
                        style={{ width: "50px", height: "50px" }}
                        className={"pyschologistProfileIcon  max-sm:!mx-[18px]"}
                        Icon={FaPaperPlane}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-span-12 psycho-info">
                  <h1>
                    Aykut <span className="t-upper">Altunışık</span>{" "}
                    <span className="last-text">HAKKINDA:</span>
                  </h1>
                  <p>
                    {
                      showFull ? 

                        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. In sagittis vestibulum nulla condimentum gravida. Nulla leo nisi, aliquet sed commodo sodales, imperdiet ullamcorper ante. Donec nec fringilla lectus. Mauris mi dui, gravida at mauris id, gravida posuere justo. Vestibulum eget consectetur ipsum. Suspendisse molestie vehicula tortor. Aenean eu magna in elit finibus posuere vitae ac erat. Praesent euismod sed tortor id varius. Nunc mattis leo non elit semper varius. Mauris nisl dui, tempor et justo sit amet, gravida egestas arcu. Duis eget elit at erat maximus efficitur id ut purus. Suspendisse ut ante consectetur, iaculis nisl vel, dictum sapien. Phasellus enim erat, vulputate quis eros quis, aliquet facilisis augue. Pellentesque cursus a lacus ac tempus. Duis consequat lectus nisi, in eleifend lorem aliquet ac. Ut porttitor dolor eu luctus suscipit. Suspendisse nec ligula a tellus ullamcorper viverra nec quis eros. Morbi ut dolor sit amet neque convallis tempor vitae eget eros. Curabitur eu scelerisque dolor. Duis nec molestie eros, sit amet sollicitudin diam. Fusce vestibulum ipsum sed velit suscipit, quis pharetra tellus congue. Praesent lacinia interdum porttitor. Vestibulum egestas metus in tellus gravida, non bibendum tortor ultrices. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae; Nunc et convallis lacus. Vestibulum lacus ligula, blandit bibendum orci et, lacinia egestas odio. Praesent at laoreet eros. Quisque pulvinar scelerisque velit id pretium. Curabitur tincidunt rhoncus nunc, sed congue eros iaculis ultrices. Curabitur consectetur mauris non sem placerat, vitae blandit nunc imperdiet. Donec eu neque orci. Donec ultrices metus non ex vulputate vestibulum."

                        : 

                        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. In sagittis vestibulum nulla condimentum gravida."
                    }

                    <ButtonPrimary onClick={() => {setShowFull(!showFull)}} Text={showFull ? "Daha Az Göster" : "Daha Fazla Göster"} Icon={"text"} style={""} className="max-sm:!mt-[20px] hidden max-sm:!block">Devamını Oku</ButtonPrimary>
                    
                  </p>
                </div>
              </div>
                <div className="education-sertifika max-sm:!w-full max-sm:mt-[20px]" >
                  <div className="education-info" >
                    <h1>EĞİTİM BİLGİSİ</h1>
                    <span>2020-(Devam)</span>
                    <p>Doktora Haccettepe Üniversitesi Psikoloojik Danışmanlık ve Rehberlik</p>
                    <span>2016-2019</span>
                    <p>Yüksek Lisans Haccettepe Üniversitesi Psikoloojik Danışmanlık ve Rehberlik</p>
                    <span>2012-2016</span>
                    <p>Lisans Haccettepe Üniversitesi Psikoloojik Danışmanlık ve Rehberlik</p>
                  </div>
                  <div className="sertifika-info" >
                    <h1>SERTİFİKA BİLGİSİ</h1>
                    <span>2021</span>
                    <p>İstanbul/Biruni Üniversitesi - Aile Danışmanlığı 450 saat</p>
                    <span>2020</span>
                    <p>İstanbul/Gedik Üniversitesi - İş Sağlığı ve Güvenliği 300 saat</p>
                  </div>
                </div>
              </div>
              <div className="info col-span-8 max-sm:hidden">
                <div className="profil-info" >
                  <h1>Aykut Altunışık</h1>
                  <span className="subtitle">Öğretim Üyesi Klinik Psikolog</span>
                  <div className="counts">
                    <span>Danışan : 999</span>
                    <span>Seans : 999</span>
                  </div>
                  <div className="meeting-info">
                    <span>30dk</span>
                    <span>200 ₺</span>
                  </div>
                  <div className="stars">
                    <FaStar />
                    <FaStar />
                    <FaStar />
                    <FaStar />
                    <FaStar />
                  </div>
                  <div className="buttons">
                    <div className="" >
                      <ButtonPrimary Text={"Randevu Al"} style={{ padding: '10px 50px', fontWeight: "700" }} onClick={showRandevu} />
                    </div>
                    <div className="flex" >
                      <ButtonCircle
                        style={{ width: "50px", height: "50px" }}
                        className={"pyschologistProfileIcon"}
                        Icon={FaPhone}
                      />
                      <ButtonCircle
                        style={{ width: "50px", height: "50px" }}
                        className={"pyschologistProfileIcon"}
                        Icon={FaCamera}
                      />
                      <ButtonCircle
                        style={{ width: "50px", height: "50px" }}
                        className={"pyschologistProfileIcon"}
                        Icon={FaPaperPlane}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-span-12 psycho-info">
                  <h1>
                    Aykut <span className="t-upper">Altunışık</span>{" "}
                    <span className="last-text">HAKKINDA:</span>
                  </h1>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. In
                    sagittis vestibulum nulla condimentum gravida. Nulla leo nisi,
                    aliquet sed commodo sodales, imperdiet ullamcorper ante. Donec
                    nec fringilla lectus. Mauris mi dui, gravida at mauris id,
                    gravida posuere justo. Vestibulum eget consectetur ipsum.
                    Suspendisse molestie vehicula tortor. Aenean eu magna in elit
                    finibus posuere vitae ac erat. Praesent euismod sed tortor id
                    varius. Nunc mattis leo non elit semper varius. Mauris nisl dui,
                    tempor et justo sit amet, gravida egestas arcu. Duis eget elit
                    at erat maximus efficitur id ut purus. Suspendisse ut ante
                    consectetur, iaculis nisl vel, dictum sapien. Phasellus enim
                    erat, vulputate quis eros quis, aliquet facilisis augue.
                    Pellentesque cursus a lacus ac tempus. Duis consequat lectus
                    nisi, in eleifend lorem aliquet ac. Ut porttitor dolor eu luctus
                    suscipit. Suspendisse nec ligula a tellus ullamcorper viverra
                    nec quis eros. Morbi ut dolor sit amet neque convallis tempor
                    vitae eget eros. Curabitur eu scelerisque dolor. Duis nec
                    molestie eros, sit amet sollicitudin diam. Fusce vestibulum
                    ipsum sed velit suscipit, quis pharetra tellus congue. Praesent
                    lacinia interdum porttitor. Vestibulum egestas metus in tellus
                    gravida, non bibendum tortor ultrices. Vestibulum ante ipsum
                    primis in faucibus orci luctus et ultrices posuere cubilia
                    curae; Nunc et convallis lacus. Vestibulum lacus ligula, blandit
                    bibendum orci et, lacinia egestas odio. Praesent at laoreet
                    eros. Quisque pulvinar scelerisque velit id pretium. Curabitur
                    tincidunt rhoncus nunc, sed congue eros iaculis ultrices.
                    Curabitur consectetur mauris non sem placerat, vitae blandit
                    nunc imperdiet. Donec eu neque orci. Donec ultrices metus non ex
                    vulputate vestibulum.
                  </p>
                </div>
              </div>
              {/* <div className="col-span-12 psycho-info">
              <h1>
                Aykut <span className="t-upper">Altunışık</span>{" "}
                <span className="last-text">hakkında</span>
              </h1>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. In
                sagittis vestibulum nulla condimentum gravida. Nulla leo nisi,
                aliquet sed commodo sodales, imperdiet ullamcorper ante. Donec
                nec fringilla lectus. Mauris mi dui, gravida at mauris id,
                gravida posuere justo. Vestibulum eget consectetur ipsum.
                Suspendisse molestie vehicula tortor. Aenean eu magna in elit
                finibus posuere vitae ac erat. Praesent euismod sed tortor id
                varius. Nunc mattis leo non elit semper varius. Mauris nisl dui,
                tempor et justo sit amet, gravida egestas arcu. Duis eget elit
                at erat maximus efficitur id ut purus. Suspendisse ut ante
                consectetur, iaculis nisl vel, dictum sapien. Phasellus enim
                erat, vulputate quis eros quis, aliquet facilisis augue.
                Pellentesque cursus a lacus ac tempus. Duis consequat lectus
                nisi, in eleifend lorem aliquet ac. Ut porttitor dolor eu luctus
                suscipit. Suspendisse nec ligula a tellus ullamcorper viverra
                nec quis eros. Morbi ut dolor sit amet neque convallis tempor
                vitae eget eros. Curabitur eu scelerisque dolor. Duis nec
                molestie eros, sit amet sollicitudin diam. Fusce vestibulum
                ipsum sed velit suscipit, quis pharetra tellus congue. Praesent
                lacinia interdum porttitor. Vestibulum egestas metus in tellus
                gravida, non bibendum tortor ultrices. Vestibulum ante ipsum
                primis in faucibus orci luctus et ultrices posuere cubilia
                curae; Nunc et convallis lacus. Vestibulum lacus ligula, blandit
                bibendum orci et, lacinia egestas odio. Praesent at laoreet
                eros. Quisque pulvinar scelerisque velit id pretium. Curabitur
                tincidunt rhoncus nunc, sed congue eros iaculis ultrices.
                Curabitur consectetur mauris non sem placerat, vitae blandit
                nunc imperdiet. Donec eu neque orci. Donec ultrices metus non ex
                vulputate vestibulum. In vestibulum justo sed velit venenatis
                suscipit. Integer aliquet posuere diam quis condimentum. Duis
                consequat justo porta, feugiat lorem eget, ornare dui. Nam
                sagittis, eros quis gravida dapibus, tortor ex vestibulum quam,
                eu hendrerit libero augue nec est. Aenean sed tortor id risus
                venenatis sodales pretium ut quam. Morbi vestibulum ipsum vel
                nulla luctus, id dignissim neque placerat. Phasellus elementum
                in lorem eget auctor. Sed efficitur quam tincidunt varius
                fermentum. Vestibulum non mauris ac nibh condimentum tincidunt.
                Fusce placerat consectetur eros eget tristique. Donec mollis at
                felis vel lacinia. Mauris at nulla nec leo fringilla viverra.
                Aenean nisl quam, tincidunt ac luctus sed, scelerisque at massa.
              </p>
            </div> */}

              <div className="col-span-12 expertise  ">
                <h1>
                  Aykut <span className="t-upper">Altunışık</span>{" "}
                  <span className="last-text">uzmanlık alanları,</span>
                </h1>
                <div class="grid grid-cols-12 gap-8 list-expertise">
                  <ButtonExpert
                    classes={["col-span-2","max-sm:!col-span-12","max-sm:!w-full","max-sm:!py-[10px]"]}
                    title="Terapi Uzmanlığı"
                    style={{ padding: '1px 1px', width: '160px' }}
                  />
                  <ButtonExpert
                    classes={["col-span-2","max-sm:!col-span-12","max-sm:!w-full","max-sm:!py-[10px]"]}
                    title="Terapi Uzmanlığı"
                    style={{ padding: '1px 1px', width: '160px' }}
                  />
                  <ButtonExpert
                    classes={["col-span-2","max-sm:!col-span-12","max-sm:!w-full","max-sm:!py-[10px]"]}
                    title="Terapi Uzmanlığı"
                    style={{ padding: '1px 1px', width: '160px' }}
                  />
                  <ButtonExpert
                    classes={["col-span-2","max-sm:!col-span-12","max-sm:!w-full","max-sm:!py-[10px]"]}
                    title="Terapi Uzmanlığı"
                    style={{ padding: '1px 1px', width: '160px' }}
                  />
                  <ButtonExpert
                    classes={["col-span-2","max-sm:!col-span-12","max-sm:!w-full","max-sm:!py-[10px]"]}
                    title="Terapi Uzmanlığı"
                    style={{ padding: '1px 1px', width: '160px' }}
                  />
                  <ButtonExpert
                    classes={["col-span-2","max-sm:!col-span-12","max-sm:!w-full","max-sm:!py-[10px]"]}
                    title="Terapi Uzmanlığı"
                    style={{ padding: '10px 5px', width: '160px' }}
                  />


                </div>
              </div>

              {/* <div className="col-span-12 sertifika mt-[50px] mb-[50px] " >
              <div className="sertifika-head" onClick={() => setToggle(!toggle)}  >
                <h1>SERTİFİKALAR</h1>
                {toggle ? <FaAngleDown className="icon" /> : <FaAngleUp className="icon" />}


              </div>
              {
                toggle && <div className="sertifika-body"  >
                  <div className="sertifika-body-info"  >
                    <span>2021</span>
                    <p>İstanbul/Biruni Üniversitesi - Aile Danışmanlığı 450 saat</p>
                  </div>
                  <div className="sertifika-body-info" >
                    <span>2021</span>
                    <p>İstanbul/Biruni Üniversitesi - Aile Danışmanlığı 450 saat</p>
                  </div>
                  <div className="sertifika-body-info" >
                    <span>2021</span>
                    <p>İstanbul/Biruni Üniversitesi - Aile Danışmanlığı 450 saat</p>
                  </div>
                  <div className="sertifika-body-info" >
                    <span>2021</span>
                    <p>İstanbul/Biruni Üniversitesi - Aile Danışmanlığı 450 saat</p>
                  </div>
                </div>
              }
            </div>

            <div className="col-span-12 education mt-[10px] mb-[50px] " >
              <div className="education-head" onClick={() => seteducationToggle(!educationToggle)}  >
                <h1>EĞİTİM</h1>
                {educationToggle ? <FaAngleDown className="icon" /> : <FaAngleUp className="icon" />}
              </div>
              {
                educationToggle && <div className="education-body"  >
                  <div className="education-body-info"  >
                    <span>200-2008</span>
                    <p>Esenyalı Turgut Reis İlk Öğretim Okulu</p>
                  </div>
                  <div className="education-body-info" >
                    <span>2008-2012</span>
                    <p>Pendik Anadolu Lisesi</p>
                  </div>
                  <div className="education-body-info" >
                    <span>2012-2016</span>
                    <p>Beykent Üniversitesi - Psikoloji</p>
                  </div>
                </div>
              }
            </div> */}


              <div className="col-span-12 comments">
                <h1 style={{ fontSize: "24px", margin: "20px" }}>
                  Müşteri yorumları:
                </h1>

                <div className="top-stars max-sm:!flex max-sm:!items-center max-sm:!flex-col">
                  {comments.map((comment, key) => {
                    return (
                      <Star
                        activeComment={activeComment}
                        keyT={key}
                        setActiveComment={setActiveComment}
                        classes={[activeComment == key ? "active" : ""," max-sm:!mt-[10px]"]}
                        starCount={comment.startCount}
                      />
                    );
                  })}
                </div>

                {/* <div class="">
                  <Slider {...settings}>
                    <div className="col-span-3" > <CommentCard
                      classes={["mr-5"]}

                      text="Lorem ipsum dolor sit amet, consectetur adipiscing 
                                elit. In sagittis vestibulum nulla condimentum 
                                gravida. Nulla leo nisi, aliquet sed commodo 
                                sodales, imperdiet ullamcorper ante. "
                      starCount={4}
                      starStyle={{
                        marginRight: "10px",
                        fill: "#146C94",
                        width: "20px",
                        height: "20px",
                      }}
                    />
                    </div>
                    <div className="col-span-3"> <CommentCard
                      classes={["mr-5"]}
                      text="Lorem ipsum dolor sit amet, consectetur adipiscing 
                                elit. In sagittis vestibulum nulla condimentum 
                                gravida. Nulla leo nisi, aliquet sed commodo 
                                sodales, imperdiet ullamcorper ante. "
                      starCount={4}
                      starStyle={{
                        marginRight: "10px",
                        fill: "#146C94",
                        width: "20px",
                        height: "20px",
                      }}
                    />
                    </div>
                    <div className="col-span-3"> <CommentCard
                      classes={["mr-5"]}
                      text="Lorem ipsum dolor sit amet, consectetur adipiscing 
                                elit. In sagittis vestibulum nulla condimentum 
                                gravida. Nulla leo nisi, aliquet sed commodo 
                                sodales, imperdiet ullamcorper ante. "
                      starCount={4}
                      starStyle={{
                        marginRight: "10px",
                        fill: "#146C94",
                        width: "20px",
                        height: "20px",
                      }}
                    />
                    </div>
                    <div className="col-span-3"> <CommentCard
                      classes={[]}
                      text="Lorem ipsum dolor sit amet, consectetur adipiscing 
                                elit. In sagittis vestibulum nulla condimentum 
                                gravida. Nulla leo nisi, aliquet sed commodo 
                                sodales, imperdiet ullamcorper ante. "
                      starCount={4}
                      starStyle={{
                        marginRight: "10px",
                        fill: "#146C94",
                        width: "20px",
                        height: "20px",
                      }}
                    />
                    </div>
                  </Slider>
                </div> */}
              </div>



              <div className="col-span-12 mb-[50px]" >
                <div className="grid grid-cols-12 gap-8  "  >
                  <div className="form-group col-span-12">
                    <label htmlFor="firstName">Adınız</label>
                    <input
                      type="text"
                      className="form-control"
                      id="firstName"
                      required
                    />
                  </div>
                  <div className="col-span-12" >
                    <div className="form-group col-md-6">
                      <label htmlFor="lastName">Soyadınız</label>
                      <input
                        type="text"
                        className="form-control"
                        id="lastName"
                        required
                      />
                    </div>
                  </div>
                </div>

                <div className="comment-do " >
                  <div className="form-group">
                    <label htmlFor="aboutYourself">
                      Yorumunuzu yapınız
                    </label>
                    <textarea
                      className="form-control"
                      id="aboutYourself"
                      rows="5"
                      required
                    ></textarea>
                  </div>
                  <div className="form-group">
                    <label htmlFor="category">Yıldız Seçimi</label>
                    <select className="form-control" id="category" required>
                      <option value="">Kaç Yıldız Vereceğinizi Seçiniz</option>
                      <option value="category1">1 Yıldız</option>
                      <option value="category2">2 Yıldız</option>
                      <option value="category3">3 Yıldız</option>
                      <option value="category3">4 Yıldız</option>
                      <option value="category3">5 Yıldız</option>
                    </select>
                  </div>


                </div>
                <div className="comment-button flex justify-center" >
                  <ButtonPrimary Text="GÖNDER" style={{ padding: '10px 70px' }} />
                </div>
              </div>
            </div>
          </div>
          <div className="resp-psycholog-profile">

          </div>
        </div>
      </section>

      <Footer />


    </div>

  );
};


