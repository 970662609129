import React from "react";
import Header from "../../components/Headers/HeaderDefault";
import Footer from "../../components/Footer/footer";
import { DateCard } from "../../components/Cards/DateCard";
import "./PaymentHistory.css";
import Profile from "../../Images/user.png";

export const PaymentHistoryNd = () => {
  return (
    <>
      <Header />

      <section>
        <div className="container mx-auto main">
          <div className="h">
            <p
              className="montserrat-b "
              style={{ fontSize: "30px", margin: "10px" }}
            >
              Randevu Geçmişi
            </p>
          </div>
          <div className="container">
            <div className="content">
              <div className="datecard-resp"><DateCard /></div>
              <div className="datecard-resp"><DateCard /></div>

            </div>
          </div>
        </div>
      </section>

      <section style={{ marginBottom: "150px" }}>
        <div className="container mx-auto">
          <div className="main-bottom">
            <div className="h">
              <p
                className="montserrat-b text-center"
                style={{ fontSize: "30px", margin: "20px" }}
              >
                Profil Bilgileri
              </p>
            </div>
          </div>
        </div>
        <div>
          <div className="infos-section">
            <div className="card-profile">
              <div className="pyschoCardProfileImage">
                <img src={Profile} alt="" />
              </div>
              <div
                className="online-info"
                style={{
                  boxShadow: "0px 0px 11px -5px rgba(0, 0, 0, 1)",
                  left: "184px",
                }}
              >
                <p>Çevrimiçi</p>
                <span className="online"></span>
              </div>
            </div>
          </div>
          <div className="card-section-PH">
            <div className="reserve-input">
              <input className="input-for-font" type="text" placeholder="İsim: İsim Soyisim" />
              <input
                className="input-for-font"
                type="text"
                placeholder="E-Posta: isimsoyisim@eposta.com"
              />
              <input className="input-for-font" type="tel" placeholder="Telefon: +90|XXX XXX XX XX" />
            </div>
            <div className="reserve-button">
              <button className="montserrat-m">Düzenle</button>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
};
