import React from "react";
import HeaderDefault from "../../components/Headers/HeaderDefault";
import Footer from "../../components/Footer/footer";
import "./apply.css";
import { Link } from "react-router-dom";
import { ButtonPrimary } from "../../components/Button/ButtonPrimary";

export const Apply = () => {
  return (
    <>
      <HeaderDefault />
      <section style={{ marginBottom: "100px" }}>
        <div className="container mx-auto">
          <div className="row">
            <p className="text-h-ts montserrat-b mb-5">
              Danışman Başvuru Formu
            </p>
            <div style={{ display: "flex", justifyContent: "center", margin: "10px" }}>
              <Link to={"/contact"} className="link-apply">
                <ButtonPrimary Text={"İletişim Formu"} />
              </Link>
            </div>

            <div className="contactList">
              <span>
                Anopia ekibine katılmak ve online danışmanlık hizmeti vermek
                için başvurunuzu değerlendirmek üzere formu doldurmanız
                gerekmektedir. Başvurunuzu inceleyerek Mail/SMS ile size en kısa
                sürede geri dönüş yapacağız.
              </span>
              <div className="flex ">
                <form className="mt-5 w-1/2 form-form-contact">
                  <div className="form-group">
                    <label htmlFor="category">Kategori Seçimi</label>
                    <select className="form-control" id="category" required>
                      <option value="">Lütfen bir kategori seçin</option>
                      <option value="category1">Kategori 1</option>
                      <option value="category2">Kategori 2</option>
                      <option value="category3">Kategori 3</option>
                    </select>
                  </div>

                  <div className="form-row">
                    <div className="form-group col-md-6">
                      <label htmlFor="firstName">Adınız</label>
                      <input
                        type="text"
                        className="form-control"
                        id="firstName"
                        required
                      />
                    </div>

                    <div className="form-group col-md-6">
                      <label htmlFor="lastName">Soyadınız</label>
                      <input
                        type="text"
                        className="form-control"
                        id="lastName"
                        required
                      />
                    </div>
                  </div>

                  <div className="form-group">
                    <label htmlFor="phone">Telefon Numaranız</label>
                    <input
                      type="tel"
                      className="form-control"
                      id="phone"
                      required
                    />
                  </div>

                  <div className="form-group">
                    <label htmlFor="email">E-posta</label>
                    <input
                      type="email"
                      className="form-control"
                      id="email"
                      required
                    />
                  </div>

                  <div className="form-group">
                    <label htmlFor="aboutYourself">
                      Kendiniz Hakkında Bilgi Veriniz
                    </label>
                    <textarea
                      className="form-control"
                      id="aboutYourself"
                      rows="5"
                      required
                    ></textarea>
                  </div>

                  <p className="text-[14px] mb-5">
                    Özgeçmişinizi, diploma ve sertifikalarınızı .pdf ve .docx
                    formatlarında yükleyebilirsiniz.
                  </p>

                  <div className="form-group">
                    <label htmlFor="cvUpload" className="cvUpload">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke-width="1.5"
                        stroke="currentColor"
                        aria-hidden="true"
                        class="h-4 w-4 text-primary-500"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          d="M9 8.25H7.5a2.25 2.25 0 00-2.25 2.25v9a2.25 2.25 0 002.25 2.25h9a2.25 2.25 0 002.25-2.25v-9a2.25 2.25 0 00-2.25-2.25H15m0-3l-3-3m0 0l-3 3m3-3V15"
                        ></path>
                      </svg>
                      <span className=""> CV Yükle</span>
                    </label>
                    <div className="file-upload">
                      <input
                        type="file"
                        className="form-control-file"
                        id="cvUpload"
                        required
                      />
                    </div>
                  </div>

                  <div className="form-group">
                    <label htmlFor="cvUpload" className="cvUpload">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke-width="1.5"
                        stroke="currentColor"
                        aria-hidden="true"
                        class="h-4 w-4 text-primary-500"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          d="M9 8.25H7.5a2.25 2.25 0 00-2.25 2.25v9a2.25 2.25 0 002.25 2.25h9a2.25 2.25 0 002.25-2.25v-9a2.25 2.25 0 00-2.25-2.25H15m0-3l-3-3m0 0l-3 3m3-3V15"
                        ></path>
                      </svg>
                      <span className=""> Diploma / Sertifika Yükle</span>
                    </label>
                    <div className="file-upload">
                      <input
                        type="file"
                        className="form-control-file"
                        id="cvUpload"
                        required
                      />
                    </div>
                  </div>


                  <button
                    type="submit"
                    className="bg-[#146C94] btn btn-primary h-[50px] w-full"
                  >
                    Başvur
                  </button>
                </form>
                <div className="w-1/2 p-10 image-image-contact">
                  <img
                    src="https://hidoctor.health/_next/image?url=%2Fimages%2FDoctorApply%2Fdoctor-apply-tr-banner.png&w=640&q=75"
                    alt=""
                    srcset=""
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};
