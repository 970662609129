import React from "react";
import HeaderDefault from "../../components/Headers/HeaderDefault";
import "./ExpertLog.scss";
import { FaFacebook, FaGoogle } from "react-icons/fa";
import { ButtonPrimary } from "../../components/Button/ButtonPrimary";
import Anopia from "../../img/Group-61.jpg";
import { Link, NavLink } from "react-router-dom";
import Footer from "../../components/Footer/footer";

export const UserRegistration = () => {
  return (
    <>
      <HeaderDefault />
      <div className="container mx-auto" style={{ marginBottom: "30px" }}>
        <div className="login-form w-100">
          <div className="flex items-center justify-center max-lg:flex-col-reverse	">
            <div className="w-1/2 max-lg:w-full continue-with mx-auto flex justify-center">
              <div className="max-lg:w-full justify-center items-center max-lg:p-5">
                <h1>Kayıt Ol</h1>
                <div className="facebook max-lg:w-full !mb-5">
                  <button className="flex justify-center items-center face-log">
                    <FaFacebook className="face" /> <span>ile kayıt ol</span>{" "}
                  </button>
                </div>
                <div className="google max-lg:w-full">
                  <button className="flex justify-center items-center mt-5 face-log">
                    <FaGoogle /> <span>ile kayıt ol</span>{" "}
                  </button>
                </div>

                <div class="login-form-line w-full">
                  <span>or</span>
                </div>

                <div className="user-area mt-[70px]">
                  <div className="name-surname max-lg:flex max-lg:flex-col mb-4">
                    <div className="user-nd max-lg:mb-5 mr-5">
                      <label className="!text-[15px]">İsim:</label>
                      <input
                        type="text"
                        placeholder="İsim"
                        className="!h-[40px]"
                      />
                    </div>
                    <div className="user-nd max-lg:mb-5">
                      <label className="!text-[15px]">Soyisim:</label>
                      <input
                        type="text"
                        placeholder="Soyisim"
                        className="!h-[40px]"
                      />
                    </div>
                  </div>

                  <div className="user !mb-5">
                    <label className="!text-[15px]">E-posta:</label>
                    <input
                      type="text"
                      placeholder="E-Posta"
                      className="!h-[40px]"
                    />
                  </div>
                  <div className="user !mb-5">
                    <label className="!text-[15px]">Doğum Tarihi</label>
                    <input
                      type="date"
                      placeholder="Doğum Tarihi"
                      className="!h-[40px]"
                    />
                  </div>
                  <div className="user !mb-5">
                    <label className="!text-[15px]">Kullanıcı Adı:</label>
                    <input
                      type="text"
                      placeholder="Kullanıcı Adı"
                      className="!h-[40px]"
                    />
                  </div>
                  <div className="user !mb-5">
                    <label className="!text-[15px]">Şifre:</label>
                    <input
                      type="password"
                      placeholder="Şifre"
                      className="!h-[40px]"
                    />
                  </div>
                  <div
                    className="doc-down !mb-5 "
                    style={{
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <label className="uzmanlik font-bold !text-[15px]">
                      Uzmanlık belgesi:
                    </label>

                    <input type="file" id="file" className="!p-[8px]" />
                  </div>
                </div>
                <div className="">
                  <ButtonPrimary
                    style={{
                      width: "250px",
                      height: "50px",
                    }}
                    className="max-lg:!w-full mx-auto flex justify-center max-lg:mt-5"
                    Text={"Kayıt Ol"}
                  />
                </div>
              </div>
            </div>
            <div className="w-1/2 max-lg:w-full">
              <div className="right text-center mx-auto">
                <Link to="/expert-registration">
                  <button className="nt-active !text-[15px]">Uzman</button>
                </Link>
                <Link to="/user-registration">
                  <button className="active !text-[15px]">Kullanıcı</button>
                </Link>
              </div>
              <div className="anopia-img">
                <img src={Anopia} alt="" className="mx-auto" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};
