import React, { useState } from "react";
import Header from "../../components/Headers/HeaderDefault";
import Footer from "../../components/Footer/footer";
import Profile from "../../Images/user.png";
import "./mappointment.css";
import {
  BsFillSendCheckFill,
  BsTelephone,
  BsCameraVideo,
} from "react-icons/bs";
import Calendar from "react-calendar";

export const MakingAppointmentTwo = () => {
  return (
    <div className="">
      <Header />
      <section className="mb-16 md:mb-0">
        <div className="md:items-center md:mb-8">
          <div className="md:w-1/2 md:text-center mx-auto md:mb-0 mb-6">
            <div className="card-profil-img mx-auto">
              <img src={Profile} alt="" />
            </div>
            <ul>
              <li className="first-text  montserrat-b text-center">
                İsim Soyisim
              </li>
              <li className="second-text text-center">
                Öğretim Üyesi Klinik Psikolog
              </li>
            </ul>
          </div>
          <div className="md:w-1/2 mx-auto !mt-5">
            <div className="Reservation-Card">
              <div className="reserve-header">
                <p className="first-text text-[20px] text-center !mb-5 max-lg:!text-[15px] montserrat-b">
                  Lütfen Randevu Bilgilerini Giriniz
                </p>
              </div>
              <div className="reserve-input-second w-full">
                <input
                  type="text"
                  placeholder="İsim:"
                  className="mb-4 md:mr-4 md:mb-0"
                />
                <input
                  type="text"
                  placeholder="E-posta:"
                  className="mb-4 md:mr-4 md:mb-0"
                />
                <input
                  type="date"
                  placeholder="Tarih:"
                  className="mb-4 md:mr-4 md:mb-0"
                />
                <input
                  type="time"
                  placeholder="Saat:"
                  className="mb-4 md:mr-4 md:mb-0"
                />
                <input
                  type="text"
                  placeholder="Seans:"
                  className="mb-4 md:mr-4 md:mb-0"
                />
              </div>
              <div className="w-full md:flex mt-5">
                <div className="w-full md:w-1/3 flex justify-center md:justify-start mb-2 md:mb-0">
                  <button className="flex items-center bg-[#bfcddf] mr-5 max-lg:mr-0 space-x-1 montserrat-m appointmentBtn">
                    <span> 999₺</span>
                  </button>
                </div>
                <div className="w-full md:w-1/3 flex justify-center mb-2 md:mb-0">
                  <button className="flex items-center bg-[#bfcddf] mr-5  max-lg:mr-0 space-x-1 montserrat-m appointmentBtn">
                    <span> 2500₺</span>
                  </button>
                </div>
                <div className="w-full md:w-1/3 flex justify-center md:justify-end">
                  <button className="flex items-center bg-[#bfcddf] space-x-1 montserrat-m appointmentBtn">
                    <span> 8200₺</span>
                  </button>
                </div>
              </div>

              <div className="cards-third-section mb-6 md:mb-0 mt-[20px] max-lg:w-full">
                <ul className="mt-4 md:flex md:space-x-4 max-lg:!block ">
                  <li className="mb-2 md:mb-0">
                    <div className="reserve-buttonnd max-lg:!w-full">
                      <button className="flex items-center space-x-1 montserrat-m appointmentBtn">
                        <BsTelephone className="w-5 h-5" />
                        <span>Seans başı +179₺</span>
                      </button>
                    </div>
                  </li>
                  <li className="mb-2 md:mb-0">
                    <div className="reserve-buttonnd  max-lg:!w-full">
                      <button className="flex items-center space-x-1 montserrat-m appointmentBtn">
                        <BsCameraVideo className="w-5 h-5" />
                        <span>Seans başı +179₺</span>
                      </button>
                    </div>
                  </li>
                  <li className="mb-2 md:mb-0">
                    <div className="reserve-buttonnd  max-lg:!w-full">
                      <button className="flex items-center space-x-1 montserrat-m appointmentBtn">
                        <BsFillSendCheckFill className="w-5 h-5" />
                        <span>Seans başı +179₺</span>
                      </button>
                    </div>
                  </li>
                </ul>
              </div>

              <div className="reserve-button mt-[60px]">
                <button className="montserrat-b">Randevu Oluştur</button>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
};
