import React from "react";
import "./button.css";

export const ButtonCircle = ({ Icon, style, className }) => {
  return (
    <button
      class={`psycho-right-button bg-[#146C94] btnCircleIcon hover:bg-[#146C94] text-white font-bold rounded-full p-[5px] ${className}`}
      style={{ ...style }}
    >
      <Icon />
    </button>
  );
};
