import React, { useState } from "react";
import Header from "../../components/Headers/HeaderDefault";
import Footer from "../../components/Footer/footer";
import "./HomePage.css";
import HomePageFirstImage from "../../img/HomePageFirst.png";
import Anopia from "../../img/ANOPİA.png";
import { ButtonPrimary } from "../../components/Button/ButtonPrimary";
import { ButtonCircle } from "./../../components/Button/ButtonCircle";
import {
  BsFillSendCheckFill,
  BsTelephone,
  BsCameraVideo,
  BsCheckCircle,
  BsArrowRight,
} from "react-icons/bs";
import { HpFeatures } from "../../components/HpFeatures/HpFeatures";
import { PsychoCard } from "./../../components/Cards/PsychoCard";
import FaqItem from "../../components/Faq/FaqItem";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";

import "slick-carousel/slick/slick-theme.css";
import { Link } from "react-router-dom";

export const HomePage = () => {
  const [selectedTab, setSelectedTab] = useState("PSİKOLOG");

  const handleTabClick = (tabName) => {
    setSelectedTab(tabName);
  };




  var settings = {
    arrows: true,
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    initialSlide: 0,

    responsive: [
      {
        breakpoint: 1280,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 3,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 3,
          infinite: true,
          dots: false,
        },
      },

      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div className="homepage ">
      <Header />

      <section>
        <div className="container mx-auto">
          <div className="homepage-first-section">
            <div className="text-image-container">
              <div className="text-homepage">
                <p className="text-h-first montserrat-b ">
                  İhtiyaç duyduğunuz kişilerle
                </p>
                <p className="text-h-second montserrat-eb">online görüşme</p>
                <p className="text-h-first montserrat-b">
                  Artık her şey bir tık uzağında
                </p>
                <p className="text-paragraph-first montserrat-m max-lg:pr-5 max-lg:pl-5">
                  Terapinin en kolay haliyle tanışma vakti! Hemen psikolog
                  seçerek istediğiniz yerden, istediğiniz şekilde bağlanın.{" "}
                  <span className="onlineTeraphy montserrat-b">
                    Online terapi
                  </span>{" "}
                  ile sorunlardan kurtularak mutlu bir hayata adım atın!
                </p>
                <div className="buttons-homepage">
                  <Link to="/experts">
                    <ButtonPrimary
                      className={`max-lg:!w-[200px] max-lg:!h-[50px]`}
                      style={{
                        width: "250px",
                        height: "50px",
                        fontWeight: "700",
                      }}
                      Text={"HEMEN BAŞLA"}
                    />
                  </Link>
                  <div className="circles">
                    <ButtonCircle
                      className={`max-lg:!w-[50px] max-lg:!h-[50px]`}
                      style={{
                        width: "70px",
                        height: "70px",
                        marginRight: "10px",
                      }}
                      Icon={BsTelephone}
                    />
                    <ButtonCircle
                      className={`max-lg:!w-[50px] max-lg:!h-[50px]`}
                      style={{
                        width: "70px",
                        height: "70px",
                        marginRight: "10px",
                      }}
                      Icon={BsCameraVideo}
                    />
                    <ButtonCircle
                      className={`max-lg:!w-[50px] max-lg:!h-[50px]`}
                      style={{ width: "70px", height: "70px" }}
                      Icon={BsFillSendCheckFill}
                    />
                  </div>
                </div>
              </div>

              <div className="image-homepage xl:justify-center ">
                <img src={HomePageFirstImage} alt="" />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="container mx-auto">
          <div className="homepage-second-section flex flex-col-reverse md:flex-row items-center justify-center">
            <div className="second-image">
              <img src={Anopia} alt="" className="w-48 md:w-[200px]" />
            </div>
            <div className="second-others mt-5 md:mt-0 md:ml-8 text-center">
              <div className="grid grid-cols-2 gap-4 md:gap-8 max-lg:grid-cols-1">
                <div className="flex md:text-left md:mx-2 items-center font-bold text-[16px]">
                  <BsCheckCircle className="mr-2" />
                  <span>Alanında Uzman Kişiler</span>
                </div>
                <div className="flex md:text-left md:mx-2 items-center font-bold text-[16px]">
                  <BsCheckCircle className="mr-2" />
                  <span>7/24 Ulaşılabilir</span>
                </div>
                <div className="flex md:text-left md:mx-2 items-center font-bold text-[16px]">
                  <BsCheckCircle className="mr-2" />
                  <span>İade Garantili</span>
                </div>
                <div className="flex md:text-left md:mx-2 items-center font-bold text-[16px]">
                  <BsCheckCircle className="mr-2" />
                  <span>100% Güvenilir</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="container mx-auto">
          <div className="third-section">
            <p className="text-[25px] mb-5 text-center montserrat-b max-lg:!text-[20px]">
              İhtiyacına Uygun Uzmanı Seç
            </p>
            <div className="buttongroup-third flex flex-wrap mb-5 justify-center">
              <ButtonPrimary
                className={`tabBtnPrimary  m-3   ${selectedTab == "PSİKOLOG" && "active"
                  }`}
                style={{
                  width: "150px",
                  height: "40px",
                  justifycontent: "center",
                  textalign: "center",
                }}
                Text={"PSİKOLOG"}
                onClick={() => handleTabClick("PSİKOLOG")}
              />
              <ButtonPrimary
                className={`tabBtnPrimary  m-3 ${selectedTab == "ÖĞRETMEN" && "active"
                  }`}
                style={{
                  width: "150px",
                  height: "40px",
                  justifycontent: "center",
                  textalign: "center",
                }}
                Text={"ÖĞRETMEN"}
                onClick={() => handleTabClick("ÖĞRETMEN")}
              />
              <ButtonPrimary
                className={`tabBtnPrimary  m-3 ${selectedTab == "AVUKAT" && "active"
                  }`}
                style={{
                  width: "150px",
                  height: "40px",
                  justifycontent: "center",
                  textalign: "center",
                }}
                Text={"AVUKAT"}
                onClick={() => handleTabClick("AVUKAT")}
              />
              <ButtonPrimary
                className={`tabBtnPrimary  m-3 ${selectedTab == "DİYETİSYEN" && "active"
                  }`}
                style={{
                  width: "150px",
                  height: "40px",
                  justifycontent: "center",
                  textalign: "center",
                }}
                Text={"DİYETİSYEN"}
                onClick={() => handleTabClick("DİYETİSYEN")}
              />
            </div>
            <div className=" w-100">
              {selectedTab == "PSİKOLOG" && (
                <>
                  <Slider {...settings}>
                    <div>
                      <PsychoCard title={"PSİKOLOG"} />
                    </div>
                    <div>
                      <PsychoCard title={"PSİKOLOG"} />
                    </div>
                    <div>
                      <PsychoCard title={"PSİKOLOG"} />
                    </div>
                    <div>
                      <PsychoCard title={"PSİKOLOG"} />
                    </div>
                    <div>
                      <PsychoCard title={"PSİKOLOG"} />
                    </div>
                    <div>
                      <PsychoCard title={"PSİKOLOG"} />
                    </div>
                    <div>
                      <PsychoCard title={"PSİKOLOG"} />
                    </div>
                  </Slider>
                </>
              )}

              {selectedTab == "ÖĞRETMEN" && (
                <>
                  <Slider {...settings}>
                    <div>
                      <PsychoCard title={"ÖĞRETMEN"} />
                    </div>
                    <div>
                      <PsychoCard title={"ÖĞRETMEN"} />
                    </div>
                    <div>
                      <PsychoCard title={"ÖĞRETMEN"} />
                    </div>
                    <div>
                      <PsychoCard title={"ÖĞRETMEN"} />
                    </div>
                    <div>
                      <PsychoCard title={"ÖĞRETMEN"} />
                    </div>
                    <div>
                      <PsychoCard title={"ÖĞRETMEN"} />
                    </div>
                    <div>
                      <PsychoCard title={"ÖĞRETMEN"} />
                    </div>
                  </Slider>
                </>
              )}
              {selectedTab == "AVUKAT" && (
                <>
                  <Slider {...settings}>
                    <div>
                      <PsychoCard title={"AVUKAT"} />
                    </div>
                    <div>
                      <PsychoCard title={"AVUKAT"} />
                    </div>
                    <div>
                      <PsychoCard title={"AVUKAT"} />
                    </div>
                    <div>
                      <PsychoCard title={"AVUKAT"} />
                    </div>
                    <div>
                      <PsychoCard title={"AVUKAT"} />
                    </div>
                    <div>
                      <PsychoCard title={"AVUKAT"} />
                    </div>
                    <div>
                      <PsychoCard title={"AVUKAT "} />
                    </div>
                  </Slider>
                </>
              )}
              {selectedTab == "DİYETİSYEN" && (
                <>
                  <Slider {...settings}>
                    <div>
                      <PsychoCard title={"DİYETİSYEN"} />
                    </div>
                    <div>
                      <PsychoCard title={"DİYETİSYEN"} />
                    </div>
                    <div>
                      <PsychoCard title={"DİYETİSYEN"} />
                    </div>
                    <div>
                      <PsychoCard title={"DİYETİSYEN"} />
                    </div>
                    <div>
                      <PsychoCard title={"DİYETİSYEN"} />
                    </div>
                    <div>
                      <PsychoCard title={"DİYETİSYEN"} />
                    </div>
                    <div>
                      <PsychoCard title={"DİYETİSYEN "} />
                    </div>
                  </Slider>
                </>
              )}
            </div>
          </div>
        </div>
      </section>

      <section style={{
        alignItems: "center",
        justifyContent: "center",
        display: "flex"
      }}>
        <Link to="/experts">
          <button className="text-h-ts montserrat-b special-button-tumexperts" style={{ margin: "0px", textDecoration: "underline", color: "black" }}>
            Tüm Uzmanları Gör
            <BsArrowRight
              style={{ width: "40px", height: "40px" }}
            />
          </button>
        </Link>
      </section>

      <section style={{ padding: "20px" }}>
        <div className="container mx-auto">
          <div className="homepage-first-section">
            <div className="text-image-container">
              <div className="text-homepage">
                <p className="text-h-first montserrat-eb">
                  Metaverse Psikolojik
                </p>
                <p className="text-h-first montserrat-eb">
                  Danışmanlık Hizmetimiz
                </p>
                <p className="text-h-second montserrat-eb">ÇOK YAKINDA</p>
                <p className="text-h-first montserrat-eb">Sizlerle</p>

                <div className="buttons-homepage">
                  <Link to="/coming-soon">
                    <ButtonPrimary
                      style={{
                        width: "250px",
                        height: "50px",
                        fontWeight: "700",
                      }}
                      Text={"Şimdi Keşfet"}
                    />
                  </Link>
                </div>
              </div>

              <div className="image-homepage">
                <img
                  src="https://hidoctor.health/_next/image?url=%2Fimages%2FConversionDesign%2FHowToUse%2F3.png&w=1920&q=75"
                  alt=""
                  srcset=""
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section style={{ marginBottom: "100px" }}>
        <div className="container mx-auto">
          <div className="">
            <p className="text-h-tss montserrat-b">Sık Sorulan Sorular</p>
            <div className="faqList 2xl:col-span-12 lg:col-span-12 md:col-span-6 sm:col-span-3 ">
              <FaqItem />
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
};
