import React from "react";
import { FaStar } from "react-icons/fa";
import "./Star.css";

export default function Star({
  keyT,
  activeComment,
  setActiveComment,
  starCount,
  starStyle,
}) {
  const stars = () => {
    var items = [];
    for (var i = 0; i < 5; i++) {
      if (i <= starCount) {
        items.push(<FaStar style={starStyle} className="active" />);
      } else {
        items.push(<FaStar className="star" />);
      }
    }

    return items;
  };

  const setActiveCommentComp = () => {
    setActiveComment(keyT);
  };

  return (
    <div
      onClick={() => {
        setActiveCommentComp();
      }}
      className={["card-star max-sm:!mt-[10px]", activeComment === keyT ? "active" : ""].join(" ")}
    >
      {starCount ? stars() : ""}
    </div>
  );
}
