import React, { useState } from 'react';
import './Search.css';
import { BsSearch } from 'react-icons/bs';

const SearchBar = () => {
    const [searchTerm, setSearchTerm] = useState('');

    const handleSearch = () => {
        // Burada yapılacak işlem, arama sonuçlarını gösterme veya başka bir eylem olabilir.
        console.log('Aranan ifade:', searchTerm);
    };

    const handleInputChange = (event) => {
        setSearchTerm(event.target.value);
    };

    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            handleSearch();
        }
    };

    return (
        <div className="search-bar">
            <input
                type="text"
                value={searchTerm}
                onChange={handleInputChange}
                onKeyPress={handleKeyPress}
                placeholder="Ara..."
            />
            <button onClick={handleSearch} ><BsSearch /></button>
        </div>
    );
};

export default SearchBar;
