import React from 'react';
import './ClientNote.css';

export const ClientNote = (style) => {
    return (
        <div className='client-note max-sm:!w-full max-sm:!ml-0' >
            <div>
                <div className='card-comment-head' >
                    <h1 className='montserrat-eb text-[32px]' >Danışan İsim</h1>
                </div>
                <div className='card-comment-middle mt-[10px]' >
                    Not başlığı
                </div>
                <div className='card-comment-info montserrat-r text-[20px]  mt-[30px] ' >
                    Lorem ipsum, dolor sit amet consectetur adipisicing elit. Quam ex accusamus officiis distinctio fugiat temporibus quidem voluptates dolore?
                </div>
            </div>
        </div>

    )
}


export default ClientNote;
