import React from "react";
import "./footer.css";
import { Link } from "react-router-dom";
import logo from "../../img/logo.png";
import { BsChevronRight } from "react-icons/bs";
import { ButtonPrimary } from "../Button/ButtonPrimary";

const Footer = () => {
  const currentYear = new Date().getFullYear();

  const footerLink = (text) => (
    <Link className="h-smaller montserrat-b">
      <span className="flex align-items-center">
        <BsChevronRight />
        <span>{text}</span>
      </span>
    </Link>
  );

  return (
    <footer className="footer pt-[50px] pr-5 pl-5">
      <div className="container mx-auto">
        <div className="w-full flex max-sm:block max-lg:p-[20px]">
          <div className="w-6/12 max-sm:w-full pr-[50px] max-lg:!pr-0">
            <Link to="/" className="logo-link">
              <img
                src={logo}
                alt="Logo"
                className="logo-image mx-auto w-[120px]"
              />
            </Link>
            <p className="mt-5 text-[15px]">
              Lorem ipsum dolor sit amet consectetur, adipisicing elit. Quaerat
              eos voluptatum consectetur provident reiciendis odio ullam iste
              accusamus sit commodi?Lorem ipsum dolor sit amet. Lorem ipsum
              dolor sit amet consectetur adipisicing.{" "}
            </p>
          </div>
          <div className="w-2/12 max-sm:w-full max-sm:mt-[50px]">
            <p className="montserrat-b">ANOPİA</p>
            <ul className="mt-6">
              <li>{footerLink("Hakkımızda")}</li>
              <li>{footerLink("SSS")}</li>
              <li>{footerLink("Uzmanlıklar")}</li>
            </ul>
          </div>
          <div className="w-2/12 max-sm:w-full  max-sm:mt-[50px]">
            <p className="montserrat-b">HİZMETLER</p>
            <ul className="mt-6">
              <li>{footerLink("Psikolog")}</li>
              <li>{footerLink("Öğretmen")}</li>
              <li>{footerLink("Avukat")}</li>
              <li>{footerLink("Diyetisyen")}</li>
            </ul>
          </div>
          <div className="w-2/12 max-sm:w-full  max-sm:mt-[50px]">
            <p className="montserrat-b">SOSYAL MEDYA</p>
            <ul className="mt-6">
              <li>{footerLink("Instagram")}</li>
              <li>{footerLink("Twitter")}</li>
              <li>{footerLink("Facebook")}</li>
              <li>{footerLink("LinkedIn")}</li>
            </ul>
          </div>
        </div>

        <div
          className="w-full mt-[30px] pt-[30px] max-lg:p-5 mb-[20px]"
          style={{ borderTop: "1px solid black" }}
        >
          <div className="flex justify-between items-center">
            <Link to="/" className="logo-link">
              <img
                src={logo}
                alt="Logo"
                className="logo-image mx-auto w-[120px]"
              />
            </Link>
            <ButtonPrimary
              className=" flex justify-center !bg-black"
              Text={"Anasayfa"}
            />
          </div>
        </div>

        <div className="copyright-footer mb-5">
          <p className="montserrat-m">
            {currentYear} &copy; Tüm Hakları Saklıdır
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
