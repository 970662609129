import React from "react";
import Header from "../../components/Headers/HeaderDefault";
import Footer from "../../components/Footer/footer";
import { Link } from "react-router-dom";
import HomePageFirstImage from "../../img/HomePageFirst.png";
import { ButtonPrimary } from "../../components/Button/ButtonPrimary";
import "./comingsoon.css";
import MetaPsiko from "../../img/metapsiko.jpg";
import ReactPlayer from "react-player";

export const ComingSoon = () => {
  return (
    <>
      <Header />
      <section className="comingsoon-all   " style={{ padding: "50px" }}>
        <div className="container mx-auto">
          <div className="homepage-first-sectiont">
            <div className="text-image-container">
              <div className="text-homepage">
                <p className="text-[#146C94] font-[600] montserrat-eb text-[60px] max-md:text-[30px] max-lg:text-[40px]">
                  Meta Psikoloji
                </p>
                {/* <p className="text-h-second montserrat-eb">ÇOK YAKINDA</p>
                                <p className="text-h-first montserrat-eb">
                                    sizlerle.
                                </p> */}

                {/* <div className="buttons-homepage">
                                    <Link to="/contact" >
                                        <ButtonPrimary
                                            style={{
                                                width: "295px",
                                                height: "70px",
                                                fontWeight: "700",
                                            }}
                                            Text={"BİLGİ AL"}
                                        />
                                    </Link>

                                </div> */}
              </div>
            </div>
            <div className="coming-soon-image-homepage">
              {/* <img src={MetaPsiko} /> */}
              <ReactPlayer url="https://www.youtube.com/watch?v=i2s1gQUjS9k" />
            </div>
            <div className="w-[70%] max-lg:w-full">
              <h1 className="metaverse-title max-md:text-[20px] max-lg:text-[40px] text-[60px] text-center">
                Metaverse Nedir ?
              </h1>
              <p className="mt-5">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                Pellentesque posuere, massa nec euismod fringilla, ligula nunc
                scelerisque ante, non ornare augue urna quis odio. Cras at
                congue metus. Curabitur tristique, ante sit amet consectetur
                elementum, orci nunc maximus elit, quis rhoncus justo justo
                vitae tortor. Phasellus eget sem a lorem luctus porta vitae vel
                justo. Nullam gravida nibh ipsum, eu finibus ipsum efficitur et.
                Suspendisse ut ante quis mauris lacinia molestie at vitae purus.
                Cras faucibus justo id venenatis vehicula. Nunc non commodo
                tortor. Nullam mollis sed purus eu rhoncus. Maecenas vitae
                molestie eros. Curabitur dolor risus, imperdiet id congue eu,
                euismod sed tortor. Fusce convallis purus dignissim, rutrum eros
                id, tempus metus. Proin tempor diam id sapien posuere eleifend.
                Mauris viverra suscipit enim vitae malesuada. Vestibulum
                efficitur ut nisi quis elementum. Aliquam lacinia vestibulum
                nulla. Aliquam vulputate tellus in lorem tempus, eget faucibus
                tortor dignissim. Nulla convallis, ante vel mollis volutpat,
                odio quam aliquet ipsum, at vestibulum felis lacus eget lorem.
                Mauris a magna nulla. Integer ullamcorper, lectus sed eleifend
                venenatis, quam ante rutrum nisl, ut consectetur odio quam a
                est. Nunc volutpat pulvinar tortor, sed efficitur nisl bibendum
                efficitur. Aliquam justo sem, sagittis vitae tempor a, sodales
                vel libero. Integer mattis nulla at mauris volutpat lobortis.
                Nam ornare velit ac sem mollis, ac consequat velit hendrerit.
                Proin tellus eros, molestie quis tristique tempus, hendrerit non
                felis. Integer vitae justo lectus. Vestibulum purus dui, aliquet
                in metus sed, convallis condimentum ipsum. Sed iaculis, lorem eu
                vestibulum volutpat, diam purus semper nisl, dignissim mattis
                elit purus nec ipsum.
              </p>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};
