import React from "react";
import HeaderDefault from "../../components/Headers/HeaderDefault";
import Footer from "../../components/Footer/footer";
import "./contact.css";
import { Link } from "react-router-dom";
import { ButtonPrimary } from './../../components/Button/ButtonPrimary';

export const Contact = () => {
    return (
        <>
            <HeaderDefault />
            <section style={{ marginBottom: "100px" }}>
                <div className="container mx-auto">
                    <div className="row">

                        <p className="text-h-ts montserrat-b mb-5">
                            İletişim Formu
                        </p>
                        <div style={{ display: "flex", justifyContent: "center", margin: "10px" }}>
                            <Link to={"/apply"} className="link-apply">
                                <ButtonPrimary Text={"Danışman Başvuru Formu"} />
                            </Link>
                        </div>


                        <div className="contactList">
                            <span>
                                Anopia ekibi ile iletişime geçmek için lütfen formu doldurunuz.
                                Başvurunuzu inceleyerek Mail/SMS ile size en kısa
                                sürede geri dönüş yapacağız.
                            </span>
                            <div className="flex">
                                <form className="mt-5 w-1/2 form-form-contact">
                                    <div className="form-group">
                                        <label htmlFor="category">Konu Seçimi</label>
                                        <select className="form-control" id="category" required>
                                            <option value="">Lütfen bir konu seçin</option>
                                            <option value="category1">Kategori 1</option>
                                            <option value="category2">Kategori 2</option>
                                            <option value="category3">Kategori 3</option>
                                        </select>
                                    </div>

                                    <div className="form-row">
                                        <div className="form-group col-md-6">
                                            <label htmlFor="firstName">Adınız</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="firstName"
                                                required
                                            />
                                        </div>

                                        <div className="form-group col-md-6">
                                            <label htmlFor="lastName">Soyadınız</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="lastName"
                                                required
                                            />
                                        </div>
                                    </div>

                                    <div className="form-group">
                                        <label htmlFor="phone">Telefon Numaranız</label>
                                        <input
                                            type="tel"
                                            className="form-control"
                                            id="phone"
                                            required
                                        />
                                    </div>

                                    <div className="form-group">
                                        <label htmlFor="email">E-posta</label>
                                        <input
                                            type="email"
                                            className="form-control"
                                            id="email"
                                            required
                                        />
                                    </div>

                                    <div className="form-group">
                                        <label htmlFor="aboutYourself">
                                            Mesajınız:
                                        </label>
                                        <textarea
                                            className="form-control"
                                            id="aboutYourself"
                                            rows="5"
                                            required
                                        ></textarea>
                                    </div>





                                    <button
                                        type="submit"
                                        className="bg-[#146C94] btn btn-primary h-[50px] w-full"
                                    >
                                        Gönder
                                    </button>
                                </form>
                                <div className="w-1/2 p-10 image-image-contact">
                                    <img
                                        src="https://hidoctor.health/_next/image?url=%2Fimages%2FDoctorApply%2Fdoctor-apply-tr-banner.png&w=640&q=75"
                                        alt=""
                                        srcset=""
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </>
    );
};
