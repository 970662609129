import React from "react";
import Header from "../../components/Headers/HeaderDefault";
import Footer from "../../components/Footer/footer";
import Profile from "../../Images/user.png";
import "./modal.css";
import {
    BsFillSendCheckFill,
    BsTelephone,
    BsCameraVideo,
} from "react-icons/bs";

export default function RandevuModal() {
    return (
        <> <div className="modal-modal">
            <section style={{ marginBottom: "150px" }} className="content-modal">
                <div className="infos-section" style={{ paddingTop: "50px" }}>
                    <div className="card-profil-img">
                        <img src={Profile} alt="" />
                    </div>
                    <ul>
                        <li className="first-text montserrat-b">İsim Soyisim</li>
                        <li className="second-text">Öğretim Üyesi Klinik Psikolog</li>
                    </ul>
                </div>
                <div className="card-section">
                    <div className="card-content-modal">
                        <div className="reserve-header">
                            <p className="first-text montserrat-b">
                                Lütfen Randevu Bilgilerini Giriniz
                            </p>
                        </div>
                        <div className="scrollable-container">
                            <div className="reserve-input-second">
                                <input type="text" placeholder="İsim:" />
                                <input type="text" placeholder="E-posta:" />
                                <input type="date" placeholder="Tarih:" />
                                <input type="time" placeholder="Saat:" />
                                <input type="text" placeholder="Seans:" />
                            </div>
                            <div
                                className="cards-third-section"
                                style={{ marginBottom: "30px" }}
                            >
                                <div className="client-seans montserrat-m my-7">
                                    <p >1 Seans - 999₺
                                    </p>
                                    <p  > 3 Seans - 2500₺
                                    </p>
                                    <p>10 Seans - 8200₺</p>
                                </div>
                                <ul>
                                    <li>
                                        <div className="reserve-buttonnd">
                                            <button className="montserrat-m flex appointmentBtn">
                                                <BsTelephone
                                                    style={{
                                                        width: "15px",
                                                        height: "15px",
                                                    }}
                                                />
                                                <span>Seans başı +179₺</span>
                                            </button>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="reserve-buttonnd">
                                            <button className="montserrat-m flex appointmentBtn">
                                                <BsCameraVideo
                                                    style={{
                                                        width: "15px",
                                                        height: "15px",
                                                    }}
                                                />
                                                <span>Seans başı +179₺</span>
                                            </button>{" "}
                                        </div>
                                    </li>
                                    <li>
                                        <div className="reserve-buttonnd">
                                            <button className="montserrat-m flex appointmentBtn">
                                                <BsFillSendCheckFill
                                                    style={{
                                                        width: "15px",
                                                        height: "15px",
                                                    }}
                                                />
                                                <span>Seans başı +179₺</span>
                                            </button>{" "}
                                        </div>
                                    </li>
                                </ul>
                            </div>

                        </div>
                        <div className="reserve-button">
                            <button className="montserrat-b">Randevu Oluştur</button>
                        </div>
                    </div>
                </div>
            </section>
        </div>
        </>
    )
}






