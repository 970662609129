import React from "react";
import HeaderDefault from "../../components/Headers/HeaderDefault";
import "./making-appointment.scss";
import Image from "../../Images/user.png";
import Footer from "../../components/Footer/footer";
import { Link } from 'react-router-dom';

export const MakingAppointment = () => {
  return (
    <div className="">
      <HeaderDefault />
      <section style={{ marginBottom: "150px" }}>
        <div className="container mx-auto" >


          <div className="infos-section">
            <div className="card-profil-img">
              <img src={Image} alt="" />
            </div>
            <ul>
              <li className="first-text montserrat-b   " >İsim Soyisim</li>
              <li className="second-text">Öğretim Üyesi Klinik Psikolog</li>
            </ul>
          </div>
          <div className="card-section  w-full mx-auto max-sm:!w-full max-md:!w-full ">
            <div className="Reservation-Card w-1/2 max-sm:w-[90%] max-md:w-full  ">
              <div className="reserve-header">
                <p className="first-text montserrat-b sm:!text-[18px]  md:!text-[30px]  ">
                  Telefon Numaranızı Giriniz
                </p>
              </div>
              <div className="reserve-input">
                <input className="w-full"
                  type="tel"
                  placeholder="+90    |        "
                />
              </div>
              <div className="reserve-button">
                <Link to="/make-appointment-two">
                  <button className="montserrat-">Devam Et</button></Link>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
};
